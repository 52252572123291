@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
.gradpapa {
    align-self: center;
    margin-left: 150px;
    
    background: linear-gradient(to right, #FFF 20%, #FF0 40%, #FF0 60%, #FFF 80%);
    background-size: 200% auto;
    
    color: #000;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
    animation: shine 2s linear infinite;
    -webkit-animation: shine 2s linear infinite;
    -moz-animation: shine 2s linear infinite;

    }
    
    @keyframes shine {
    to {
        background-position: 200% center;
    }
    }

    .gradLoad {
      align-self: center;
      
      background: linear-gradient(to left, #EE7752, #E73C7E, #23A6D5, #23D5AB);
      background-size: 200% auto;
      
      color: #000;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      
      animation: shine 2s linear infinite;
      -webkit-animation: shine 2s linear infinite;
      -moz-animation: shine 2s linear infinite;
  
      }
      
      @keyframes shine {
      to {
          background-position: 200% center;
      }
      }
 

  .backpapa {
    color: #fff;
    background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
    background-size: 400% 400%;
    animation: Gradient 120s ease infinite;
    }
    
    @keyframes Gradient {
        0% {
        background-position: 0% 50%
        }
        50% {
        background-position: 100% 50%
        }
        100% {
        background-position: 0% 50%
        }
        }
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

body {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
